<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Exchange Rates"
              :data-source="dataSource"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onManage(v)"
              @on-update-cancel="(v) => loadExchangeRates()"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in fields"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="onShowModal"
      centered
      @hide="onCloseModal()"
      size="lg"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <b-form-group label="Currency" label-for="v-currency_id">
            <v-select
              v-model.number="formData.currency_id"
              label="description"
              :options="currencies"
              :reduce="(currencies) => +currencies.id"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Rate" label-for="v-exch_rate">
            <b-form-input v-model.number="formData.exchange_rate" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Year" label-for="v-exch_year">
            <b-form-input v-model.number="formData.exchange_year" />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="onCloseModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import exchangeRateApi from '@api/exchange_rates';
import currencyApi from '@api/currencies';
import { queryParameters } from '@/schema';

export default {
  name: 'ExchangeRatePage',
  components: {},
  data: () => ({
    onShowModal: false,
    dataSource: [],
    currencies: [],
    formData: {},
    fields: [
      { caption: 'Currency', field: 'currency_name' },
      { caption: 'Exchange Rate', field: 'exchange_rate' },
      { caption: 'Exchange Year', field: 'exchange_year' },
    ],
  }),
  mounted() {
    this.loadCurrencies();
    this.loadExchangeRates();
  },
  methods: {
    loadExchangeRates() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      exchangeRateApi
        .list(params)
        .then(({ data }) => {
          data.forEach((item) => {
            const currency = this.currencies.find((x) => {
              return parseInt(x.id) === item.currency_id;
            });

            item.currency_name = currency ? currency.description : '';
          });

          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadCurrencies() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      currencyApi
        .list(params)
        .then(({ data }) => {
          this.currencies = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const onSuccess = () => {
        this.loadExchangeRates();
        this.onCloseModal();
      };

      if (!this.formData.id) {
        exchangeRateApi
          .add(this.formData)
          .then(({ data }) => {
            onSuccess();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });

        return;
      }

      exchangeRateApi
        .update(this.formData)
        .then(({ data }) => {
          onSuccess();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onManage(event) {
      this.onShowModal = true;

      if (event.name === 'edit') {
        this.formData = event.data;
      }
    },
    onDelete(event) {
      exchangeRateApi
        .delete(event)
        .then(({ data }) => {
          this.loadExchangeRates();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onCloseModal() {
      this.onShowModal = false;
      this.formData = {};
    },
  },
};
</script>

<style></style>
