import api from '@apiInstance';

export default {
  add(data) {
    return api.post('exchange_rates', data);
  },
  get(id) {
    return api.fetch(`exchange_rates/${id}`);
  },
  async list(params) {
    const data = await api.fetch('/exchange_rates', { params });
    return data;
  },
  update(data) {
    return api.patch('exchange_rates', data);
  },
  delete(id) {
    return api.remove('exchange_rates', id);
  },
};
